import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Box, Typography, Grid, Hidden } from '@material-ui/core'
import Img from 'gatsby-image'
import { Fade, Slide, Zoom } from 'react-reveal'

import materialUiTheme, { fonts } from '../material-ui-theme'

import ContainedSection from '../components/ContainedSection'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import media from '../utils/media'

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      pageHeaderBackgroundImage: file(
        relativePath: { eq: "about-us-header-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maEstelaFullBodyFacingLeft: file(
        relativePath: { eq: "ma-estela-full-body-facing-left.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      pageHeaderBackgroundImage={
        data.pageHeaderBackgroundImage.childImageSharp.fluid.src
      }
      pageTitle="About Us"
    >
      <SEO title="About Us" />
      <S.ContainedSection>
        <Grid container spacing={4}>
          <Hidden mdDown>
            <Grid item lg={1} />
          </Hidden>
          <Grid item xs={12} sm={9} lg={7}>
            <Zoom>
              <S.Title gutterBottom color="secondary" variant="h3">
                Our Story
              </S.Title>
            </Zoom>
            <Hidden smUp>
              <S.ImageBox>
                <Slide bottom>
                  <Img
                    fluid={
                      data.maEstelaFullBodyFacingLeft.childImageSharp.fluid
                    }
                  />
                </Slide>
              </S.ImageBox>
            </Hidden>
            <Fade left>
              <Typography style={{ marginBottom: materialUiTheme.spacing(3) }}>
                Inspired through the comforts of a loving mother, a post-war
                restaurant was established to give customers a reminder of a
                heart-felt meal that their mothers would usually prepare.
                Unconditional love unites the bond between a mother and child;
                hence the word Ma, Chinese for mother, came to its existence.
                The heart of Ma Chicken Mami House is from a dedicated and
                passionate mother of four, Estela. Right after the war, her
                inspiration to prepare dishes were the children of the war and
                the childhood that was taken away from them. She sought for ways
                to express her compassion which resulted to cooking for families
                and friends.
              </Typography>
            </Fade>
            <Fade left>
              <Typography style={{ marginBottom: materialUiTheme.spacing(3) }}>
                Ma is a small quick service dining place enough to make you feel
                at home; Ma treats their guests with loving kindness as if they
                were her children. Every sip of hot soup will make you feel the
                warmth and tender loving care of a mother. Ma values each guest
                by remembering their favourites and ensures that they get more
                than delicious food.
              </Typography>
            </Fade>
            <Fade left>
              <Typography style={{ marginBottom: materialUiTheme.spacing(3) }}>
                Ma Chicken Mami House today follows the original recipe from
                1958. Its food choices are handpicked and is of quality. It is
                now known to be a staple go to place where you experience
                comfortable dining that feels like home and a taste of pre-war
                era. Everything is made from scratch, down to the soup stock.
                Everything is crafted by Estela's recipe. Food is savoury and
                opens up the palate. Ma Chicken Mami House also has their share
                of loyal customers who visits home after a long tiring day to
                satisfy simple yet tasty mami cravings with vegetables and
                chicken or beef.
              </Typography>
            </Fade>
            <Fade left>
              <Typography style={{ marginBottom: materialUiTheme.spacing(3) }}>
                For the next years of Ma Chicken Mami House, expansion around
                the Philippines will take place together with the team working
                behind to serve families to feel their mother's home cooked
                food. While setting the bars, Ma Chicken Mami House will be a
                part of everyone's comfort zone as Ma continues to cook
                whole-heartedly for everyone. The constant care and priority
                that Ma offers to every single individual will continue its
                legacy for the next generation. This is your home that never
                gets old and your mother's best dish that is never-changing.
                Each bite that you take will remind you of your mother's passion
                for cooking and love for you.
              </Typography>
            </Fade>
            <Fade left>
              <Typography style={{ marginBottom: materialUiTheme.spacing(2) }}>
                Don't stray around anymore, visit home...
              </Typography>
            </Fade>
            <Fade left>
              <Typography
                gutterBottom
                style={{
                  fontFamily: fonts['Brush455BT'],
                }}
                variant="h4"
              >
                Come home to Ma.
              </Typography>
            </Fade>
          </Grid>
          <Hidden xsDown>
            <Grid item sm={3} lg={4}>
              <S.ImageBox>
                <Slide right>
                  <Img
                    fluid={
                      data.maEstelaFullBodyFacingLeft.childImageSharp.fluid
                    }
                  />
                </Slide>
              </S.ImageBox>
            </Grid>
          </Hidden>
        </Grid>
      </S.ContainedSection>
    </Layout>
  )
}

const S = {
  ContainedSection: styled(ContainedSection)`
    padding-top: 60px;

    ${media.sm`
      padding-top: 60px;
    `}

    ${media.md`
      padding-top: 80px;
    `}
  `,
  ImageBox: styled(Box)`
    margin: ${materialUiTheme.spacing(5)}px auto;
    width: 148px;

    ${media.sm`
      margin-bottom: 0;
      margin-top: ${materialUiTheme.spacing(7)}px;
    `}

    ${media.md`
      width: 196px;
    `}
  `,
  Title: styled(Typography)`
    text-align: center;

    ${media.sm`
      text-align: left;
    `}
  `,
}

export default AboutUs
